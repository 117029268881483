import '../styles/global.scss'
import '../styles/reset.scss'
import '../components/header/headerAppyShop.scss'
import '../components/footer/footerAppyShop.scss'
import { appWithTranslation } from 'next-i18next'
import nextI18NextConfig from '../next-i18next.config.js'
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from '../store/configureStore'
import { ToastContainer } from 'react-toastify'
import Head from "next/head";


const MyApp = ({ Component, pageProps }) => {
    return process.browser ? (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Head>
                    <script defer data-domain="orel-geneve.ch" src="https://plausible.wm-dev.fr/js/script.js"></script>
                </Head>
                <ToastContainer/>
                <Component {...pageProps} />
            </PersistGate>
        </Provider>
    ) : (
        <Provider store={store}>
            <ToastContainer />
            <Component {...pageProps} />
        </Provider>
    )
}



export default appWithTranslation(MyApp, nextI18NextConfig)
