import {Customer, CustomerOrderInfo, DeliveryChosen} from "../interfaces/store-interfaces"
import {CartResponseDTO, TransactionResponseDTO} from "../interfaces/cart-interfaces";

export const UPDATE_CURRENCY = "UPDATE_CURRENCY"
export const ADD_TO_CART = "ADD_TO_CART"
export const UPDATE_NB_ITEMS_ON_CART = "UPDATE_NB_ITEMS_ON_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const ADD_PROMO_CODE = "ADD_PROMO_CODE"
export const DELETE_PROMO_CODE = "DELETE_PROMO_CODE";
export const ADD_CUSTOMER_ORDER_INFO = "ADD_CUSTOMER_ORDER_INFO";
export const UPDATE_QUANTITY_PRODUCT_ON_CART = "UPDATE_QUANTITY_PRODUCT_ON_CART";
export const UPDATE_CURRENT_ORDER_CART = "UPDATE_CURRENT_ORDER_CART";
export const UPDATE_CURRENT_ORDER_ID_AND_TIMEOUT_AND_CART_AND_VAT = "UPDATE_CURRENT_ORDER_ID_AND_TIMEOUT_AND_CART_AND_VAT";
export const UPDATE_CURRENT_ORDER_DELIVERY = "UPDATE_CURRENT_ORDER_DELIVERY";
export const UPDATE_CURRENT_ORDER_PSP_CHOSEN = "UPDATE_CURRENT_ORDER_PSP_CHOSEN";
export const UPDATE_CURRENT_TRANSAC = "UPDATE_CURRENT_TRANSAC";
export const UPDATE_CURRENT_ORDER_TIMEOUT = "UPDATE_CURRENT_ORDER_TIMEOUT";
export const UPDATE_CURRENT_ORDER_RESET_TO_STEP1 = "UPDATE_CURRENT_ORDER_RESET_TO_STEP1";
export const REMOVE_CART = "REMOVE_CART;"
export const LOGIN_CUSTOMER = "LOGIN_CUSTOMER;"
export const LOGOUT_CUSTOMER = "LOGOUT_CUSTOMER;"

export function updateCurrency(currency: string) {
    return {type: UPDATE_CURRENCY, currency}
}

export function addToCart(product: { idP: number, quantity: number }) {
    return {type: ADD_TO_CART, product}
}

export function removeFromCart(productId: number) {
    return {type: REMOVE_FROM_CART, productId}
}

export function updateCartWithQuantityAvailable(products: { idP: number, quantity: number }[]) {
    return {type: UPDATE_QUANTITY_PRODUCT_ON_CART, products}
}

export function removeCart() {
    return {type: REMOVE_CART}
}

export function updateNbItemsOnCart(nbItems: number) {
    return {type: UPDATE_NB_ITEMS_ON_CART, nbItems}
}

export function addPromoCode(promoCode: string) {
    return {type: ADD_PROMO_CODE, promoCode}
}

export function deletePromoCode() {
    return {type: DELETE_PROMO_CODE}
}

export function addCustomerOrderInfo(customerInfo: CustomerOrderInfo) {
    return {type: ADD_CUSTOMER_ORDER_INFO, customerInfo}
}

export function updateCurrentOrderIdAndTimeoutAndCart(orderId: number, orderTimeout: number, cart: CartResponseDTO, vatEnabled: boolean) {
    return {type: UPDATE_CURRENT_ORDER_ID_AND_TIMEOUT_AND_CART_AND_VAT, orderId, orderTimeout, cart, vatEnabled}
}

export function updateCurrentOrderCart(cart: CartResponseDTO) {
    return {type: UPDATE_CURRENT_ORDER_CART, cart}
}

export function updateCurrentOrderTimeout(timeout: number) {
    return {type: UPDATE_CURRENT_ORDER_TIMEOUT, timeout}
}

export function updateCurrentOrderDelivery(deliveryChosen: DeliveryChosen) {
    return {type: UPDATE_CURRENT_ORDER_DELIVERY, deliveryChosen}
}

export function resetCurrentOrderToStep1() {
    return {type: UPDATE_CURRENT_ORDER_RESET_TO_STEP1}
}

export function updateCurrentOrderPspChosen(pspChosen: string) {
    return {type: UPDATE_CURRENT_ORDER_PSP_CHOSEN, pspChosen}
}

export function updateCurrentTransaction(transaction: TransactionResponseDTO) {
    return {type: UPDATE_CURRENT_TRANSAC, transaction}
}

export function loginCustomer(customer: Customer) {
    return {type: LOGIN_CUSTOMER, data: customer}
}

export function logout() {
    return {type: LOGOUT_CUSTOMER}
}
