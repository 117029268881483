import {ShopState} from '../interfaces/store-interfaces'
import {config} from '../config-merchant'
import {
    ADD_TO_CART,
    UPDATE_CURRENCY,
    UPDATE_NB_ITEMS_ON_CART,
    ADD_PROMO_CODE,
    REMOVE_FROM_CART,
    DELETE_PROMO_CODE,
    ADD_CUSTOMER_ORDER_INFO,
    UPDATE_QUANTITY_PRODUCT_ON_CART,
    REMOVE_CART,
    UPDATE_CURRENT_ORDER_ID_AND_TIMEOUT_AND_CART_AND_VAT,
    UPDATE_CURRENT_ORDER_DELIVERY,
    UPDATE_CURRENT_ORDER_PSP_CHOSEN,
    UPDATE_CURRENT_ORDER_CART,
    UPDATE_CURRENT_TRANSAC,
    UPDATE_CURRENT_ORDER_TIMEOUT, UPDATE_CURRENT_ORDER_RESET_TO_STEP1, LOGIN_CUSTOMER, LOGOUT_CUSTOMER
} from './action';

const initialState: ShopState = {
    currentCurrency: config.defaultCurrency,
    shoppingCart: {},
    nbItemsOnCart: 0,
    promoCode: "",
    currentOrder: {
        cart: null,
        orderId: null,
        orderTimeout: null,
        lastTransaction: null,
        vatEnabled: true,
        deliveryChosen: {
            deliveryId: null,
            type: null
        },
        pspChosen: null,
        customer: {
            email: '',
            phone: '',
            deliveryAddress: {name: '', country: '', locality: '', postalCode: '', route: '', streetNumber: ''},
            billingAddress: {name: '', country: '', locality: '', postalCode: '', route: '', streetNumber: ''},
            deliveryOneLine: '',
            billingOneLine: '',
            tvaIntra: '',
        },
    },
    customer: null
}


export default function shopReducer(state = initialState, action): ShopState {
    switch (action.type) {
        case UPDATE_CURRENCY:
            return {
                ...state,
                currentCurrency: action.currency
            };
        case ADD_TO_CART:
            let shoppingCart = {...state.shoppingCart};
            let nbItemsOnCart = state.nbItemsOnCart + action.product.quantity;

            typeof shoppingCart[action.product.idP] === 'undefined'
                ? shoppingCart[action.product.idP] = {"quantity": action.product.quantity}
                : shoppingCart[action.product.idP]['quantity'] += action.product.quantity;

            return {
                ...state,
                shoppingCart,
                nbItemsOnCart
            };
        case REMOVE_FROM_CART:
            let shopCart = {...state.shoppingCart};
            delete shopCart[action.productId]
            return {
                ...state,
                shoppingCart: shopCart
            };
        case UPDATE_QUANTITY_PRODUCT_ON_CART:
            let newCart = {}
            for (let i = 0; i < action.products.length; i++) {
                newCart[action.products[i].productId] = {"quantity": action.products[i].quantity}
            }
            return {
                ...state,
                shoppingCart: newCart
            };
        case UPDATE_NB_ITEMS_ON_CART:
            return {
                ...state,
                nbItemsOnCart: action.nbItems
            };
        case REMOVE_CART:
            return {
                ...state,
                shoppingCart: {},
                nbItemsOnCart: 0,
                currentOrder: {
                    ...initialState.currentOrder,
                    customer: state.currentOrder.customer
                }
            };
        case ADD_PROMO_CODE:
            return {
                ...state,
                promoCode: action.promoCode
            };
        case DELETE_PROMO_CODE:
            return {
                ...state,
                promoCode: ""
            };
        case ADD_CUSTOMER_ORDER_INFO:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    customer: action.customerInfo
                }
            };
        case UPDATE_CURRENT_ORDER_ID_AND_TIMEOUT_AND_CART_AND_VAT:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    orderId: action.orderId,
                    orderTimeout: action.orderTimeout,
                    vatEnabled: action.vatEnabled,
                    cart: action.cart,
                }
            };
        case UPDATE_CURRENT_ORDER_CART:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    cart: action.cart,
                }
            };
        case UPDATE_CURRENT_ORDER_DELIVERY:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    deliveryChosen: action.deliveryChosen,
                }
            };
        case UPDATE_CURRENT_ORDER_TIMEOUT:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    orderTimeout: action.timeout,
                }
            };
        case UPDATE_CURRENT_ORDER_PSP_CHOSEN:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    pspChosen: action.pspChosen
                }
            };
        case UPDATE_CURRENT_ORDER_RESET_TO_STEP1:
            return {
                ...state,
                currentOrder: {
                    ...initialState.currentOrder,
                    customer: state.currentOrder.customer
                }
            };
        case UPDATE_CURRENT_TRANSAC:
            return {
                ...state,
                currentOrder: {
                    ...state.currentOrder,
                    lastTransaction: action.transaction
                }
            };
        case LOGIN_CUSTOMER:
            return {
                ...state,
                customer: action.data
            }
        case LOGOUT_CUSTOMER:
            return {
                ...state,
                customer: null
            }
        default:
            return state
    }
}
