import {createStore} from 'redux';
import { persistStore, persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import reducer from './reducer'

declare global { interface Window { __REDUX_DEVTOOLS_EXTENSION__: any } }

const persistConfig = {
    key: 'appyShop-1.4.0',
    storage: storage
}

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer)


export const persistor = persistStore(store);
