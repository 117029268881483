module.exports = {
    config: {
        merchantId: 2,
        merchantShopName: "Orel - Genève",
        merchantDomain: "https://orel-geneve.ch/",
        defaultMetaImage: "assets/images/meta-shop-picture.png",
        enabledLanguagues: ['fr'],
        defaultLanguage: 'fr',
        enabledCurrencies: ["CHF"],
        defaultCurrency: "CHF",
        enabledDeliveryCountry: ['ch'],
        nbProductsByPage: 6,
        shopEmail: "contact@orel-geneve.ch",
        shopPhone: "+41789117659",
        stripeCountryAccount: 'CH',
        shopAddress: "",
        shopPostalCity: "1205",
        shopCountry: "Genève",
        shopSlogan: {
            "fr": "Parfumeuse de niche",
            "en": "TODO EN Slogan."
        },
        shopMapsUrl: "",
        socialMedias: {
            facebook: "https://www.facebook.com/orelgeneve.ch",
            instagram: "https://www.instagram.com/orelgeneve.ch/"
        },
        footerLink : [
            {
                "name": "FOOTER_LNOTICE",
                "url": "/legal-notice"
            },
            {
                "name": "FOOTER_TERMS",
                "url": "/terms-of-sales"
            },
        ],
        menu: [
            {
                "name": "NAV_HOME",
                "path": "home",
                "submenu": []
            },
            {
                "name": "ORL_MENU_INSPIRATION",
                "path": "inspiration",
                "submenu": []
            },
            {
                "name": "ORL_MENU_COLLECTION",
                "path": "catalog",
                "submenu": []
            },
            {
                "name": "ORL_MENU_CREATOR",
                "path": "about",
                "submenu": []
            },
            {
                "name": "Contact",
                "path": "contact",
                "submenu": []
            }
        ],
        mondialRelayCode: "",
        mondialRelayColLivMod: "24R",
        stripeKey: "pk_live_51IDX9sFONvuSNpV2o5KatJP8qQncNXdbw5DfzLuVwEoRk6dyyxdC0U3WasFIWMO25RidY1DF6TO9HncLY1YMyDrl00vpUhDuJU",
        stripeAccount: "acct_1Mt5JCFWUpmAQtrT",
        quoteDownloadEnabled: false,
        reviewEnabled: true
    }
}
